import React, { useState, useEffect, useRef, useContext } from "react";
import { useInView, useMedia } from "../../../utils/hooks";
import { VideoSource } from "../../../components/video";
import { COLOR_OUTLINE_DARK_GREY, MEDIA_MOBILE_ALL } from "../../../layouts/default/theme";

import {
  Section,
  BackgroundWrapper,
  BackgroundVideo,
  ForegroundWrapper,
  ForegroundVideoWrapper,
  ForegroundVideo,
  ForegroundInner,
  ForegroundRow,
  Logo,
  LogoImage,
} from "./style";

import Button from "../../../components/button";
import HexOutline, { HexTransitionType } from "../../../components/hex-outline";
import { LinkProps } from "../../../components/link";
import { ComponentImageProps, sizes } from "../../../components/image";
import { useIntl } from "gatsby-plugin-intl";
import RiotLocaleContext from "../../../contexts/riot-locale";
import getGameLogo from "../../../utils/get-game-logo";

export interface Props {
  crispPoster: React.ReactElement<ComponentImageProps>;
  blurredPoster: React.ReactElement<ComponentImageProps>;
  mobilePoster: React.ReactElement<ComponentImageProps>;
  crispVideoSources: VideoSource[];
  blurredVideoSources: VideoSource[];
  mobileVideoSources: VideoSource[];
  link: LinkProps & {
    text: string;
  };
  intersectionRoot?: HTMLElement | null;
}

const Hero: React.FC<Props> = ({
  crispPoster,
  blurredPoster,
  mobilePoster,
  crispVideoSources,
  blurredVideoSources,
  mobileVideoSources,
  link,
  intersectionRoot,
}) => {
  const [logoLoaded, setLogoLoaded] = useState(false);

  const bgVideoRef = useRef<HTMLVideoElement>(null);
  const fgVideoRef = useRef<HTMLVideoElement>(null);
  const mobileVideoRef = useRef<HTMLVideoElement>(null);

  const [isMobile, setIsMobile] = useState(false);

  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  useMedia(MEDIA_MOBILE_ALL, (match) => {
    setIsMobile(match);
  });

  // Handle intersection changes
  useEffect(() => {
    if (isMobile) {
      // Pause desktop videos
      if (fgVideoRef.current) fgVideoRef.current.pause();
      if (bgVideoRef.current) bgVideoRef.current.pause();
      // Update mobile play state
      if (mobileVideoRef.current && logoLoaded) {
        if (inView) mobileVideoRef.current.play();
        else mobileVideoRef.current.pause();
      }
    } else if (fgVideoRef.current && bgVideoRef.current) {
      // Pause mobile video
      if (mobileVideoRef.current) mobileVideoRef.current.pause();
      // wait for both videos to be 'canplay' to start synced
      if (inView && logoLoaded) {
        // If videos are out of sync > 0.15s, resync
        if (
          fgVideoRef.current.readyState > 0 &&
          bgVideoRef.current.readyState > 0 &&
          Math.abs(fgVideoRef.current.currentTime - bgVideoRef.current.currentTime) > 0.15
        ) {
          bgVideoRef.current.currentTime = fgVideoRef.current.currentTime;
        }
        fgVideoRef.current.play();
        bgVideoRef.current.play();
      } else {
        fgVideoRef.current.pause();
        bgVideoRef.current.pause();
      }
    }
  }, [inView, isMobile, bgVideoRef.current, fgVideoRef.current, mobileVideoRef.current, logoLoaded]);
  const intl = useIntl();

  const riotLocale = useContext(RiotLocaleContext);

  const gameLogo = getGameLogo(riotLocale ?? "en-us");
  var link = {
    url: 'https://lienminh.vnggames.com/dang-ky/redownload/#',
    internal: false,
    text: 'play-for-free.action'
  }

  return (
    <Section ref={intersectionRef} data-testid="hero">
      <BackgroundWrapper>
        <BackgroundVideo
          lazy={!inView || isMobile}
          poster={React.cloneElement(blurredPoster, {
            lazy: !inView || isMobile,
          })}
          objectFit="cover"
          objectPosition="center top"
          playsInline
          loop
          muted
          videoRef={bgVideoRef}
          testId="hero"
        >
          {blurredVideoSources}
        </BackgroundVideo>
      </BackgroundWrapper>
      <ForegroundWrapper>
        <ForegroundVideoWrapper>
          <ForegroundVideo
            lazy={!inView || isMobile}
            poster={React.cloneElement(crispPoster, {
              lazy: !inView || isMobile,
            })}
            objectFit="cover"
            objectPosition="center center"
            playsInline
            loop
            muted
            videoRef={fgVideoRef}
            testId="hero"
          >
            {crispVideoSources}
          </ForegroundVideo>
          <ForegroundVideo
            className="is-mobile"
            lazy={!inView || !isMobile}
            poster={React.cloneElement(mobilePoster, {
              lazy: !inView || !isMobile,
            })}
            objectFit="cover"
            objectPosition="center center"
            playsInline
            loop
            muted
            videoRef={mobileVideoRef}
            testId="hero"
          >
            {mobileVideoSources}
          </ForegroundVideo>
        </ForegroundVideoWrapper>
        <HexOutline
          isActive={inView}
          className={"hex"}
          offsetAmount={10}
          offsetHorizontal={-1}
          offsetVertical={1}
          clipRightTop={30}
          strokeColor={COLOR_OUTLINE_DARK_GREY}
          accentColor={COLOR_OUTLINE_DARK_GREY}
          transition={HexTransitionType.WIRE}
          transitionDuration={2000}
          transitionDelay={500}
        />
        <ForegroundInner>
          <ForegroundRow>
            <Logo>
              <LogoImage
                src={gameLogo}
                sizes={sizes({
                  "80vw": MEDIA_MOBILE_ALL,
                  "40vw": null,
                })}
                onLoad={() => setLogoLoaded(true)}
                onError={() => setLogoLoaded(true)}
                testId="hero:logo"
              />
            </Logo>
          </ForegroundRow>
          <ForegroundRow>
            <Button link={link} isActive={inView} transitionDelay={1400} testId="hero:button">
              {intl.formatMessage({ id: link.text })}
            </Button>
          </ForegroundRow>
        </ForegroundInner>
      </ForegroundWrapper>
    </Section>
  );
};

export default Hero;
